
.tabs-header-bar {
    padding-left: 1em;
    padding-top: 10px; /* For the shadow */
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: start;
    background-color: var(--background-color);
    max-width: 100vw;
    overflow-x: auto;
}


.tab-single-header img {
    height: 3em;
    max-width: 3em;
    padding: 0.5em;
    display:block;
    margin:auto;
}

.tab-single-header svg {
    height: 3em;
    max-width: 3em;
    padding: 0.5em;
    display:block;
    margin:auto;
}


.tab-content {
    z-index: 5002;
    background-color: var(--background-color);
    color: var(--foreground-color);
    position: relative;
    padding: 1em;
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
}

.tab-single-header {
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    z-index: 5000;
    padding-bottom: 0;
    margin-bottom: 0;
}

.tab-active {
    background-color: var(--background-color);
    color: var(--foreground-color);
    z-index: 5001;
    box-shadow: 0 0 10px var(--shadow-color);
    border: 1px solid var(--background-color);
    min-width: 4em;
}

.tab-active svg {
    fill: var(--foreground-color);
    stroke: var(--foreground-color);
}

.tab-non-active {
    background-color: var(--subtle-detail-color);
    color: var(--foreground-color);
    opacity: 0.5;
    border-left: 1px solid gray;
    border-right: 1px solid gray;
    border-top: 1px solid gray;
    border-bottom: 1px solid lightgray;
    min-width: 4em;
}

.tab-non-active svg {
    fill: var(--foreground-color) !important;
    stroke: var(--foreground-color) !important;
}

.tab-non-active svg path{
    fill: var(--foreground-color) !important;
    stroke: var(--foreground-color) !important;
}
